import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AuthService } from '../../auth.service';
import { AuthDialogComponent } from '../auth-dialog.component';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
})
export class ProfileComponent {
  constructor(
    public auth: AuthService,
    private dialogRef: MatDialogRef<AuthDialogComponent>,
    private router: Router
  ) {}

  signOut = (): void => {
    this.onCancelClick();
    this.auth.signOut().then((res) => {
      this.router.navigate(['/']);
    });
  };

  onCancelClick(): void {
    this.dialogRef.close();
  }
}
