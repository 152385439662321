import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AuthDialogComponent } from '../auth/auth-dialog/auth-dialog.component';
import { AuthService } from '../auth/auth.service';

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss'],
})
export class NavComponent {
  showRecent = false;

  constructor(
    private router: Router,
    public auth: AuthService,
    private dialog: MatDialog
  ) {}

  onHomeClick(): void {
    this.router.navigate(['']);
  }

  onAddClick(): void {
    this.router.navigate(['r', 'edit']);
  }

  onCookbookClick(): void {
    this.router.navigate(['cookbook']);
  }

  onUserClick(): void {
    const dialogRef = this.dialog.open(AuthDialogComponent, {
      width: '75%',
      height: '75%',
      backdropClass: 'backdrop',
    });

    dialogRef.afterClosed().subscribe();
  }
}
