import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, tap } from 'rxjs';
import { Recipe } from '../models/recipe.model';

@Injectable({
  providedIn: 'root',
})
export class RecentRecipesService {
  private _recentRecipes: BehaviorSubject<Recipe[]> = new BehaviorSubject<
    Recipe[]
  >([]);
  public readonly recentRecipes$: Observable<Recipe[]> =
    this._recentRecipes.asObservable();

  constructor() {
    // Initialize the BehaviorSubject with data from localStorage if available
    const storedRecipes = JSON.parse(
      localStorage.getItem('recentRecipes') || '[]'
    );
    this._recentRecipes.next(storedRecipes);

    // Subscribe to the BehaviorSubject to save its state to localStorage on changes
    this._recentRecipes
      .pipe(
        tap((recipes) =>
          localStorage.setItem('recentRecipes', JSON.stringify(recipes))
        )
      )
      .subscribe();
  }

  getRecentRecipes() {
    return this._recentRecipes.value;
  }

  // Add a new recipe to the list of recent recipes
  addRecentRecipe(recipe: Recipe): void {
    if (recipe?.id == null) return;

    let currentRecipes = this._recentRecipes.value;

    currentRecipes = currentRecipes
      .filter((i) => i.id !== recipe.id)
      .slice(0, 9);

    this._recentRecipes.next([recipe, ...currentRecipes]);
  }

  // Remove a recipe from the list of recent recipes by RecipeId
  removeRecentRecipe(recipeId: string): void {
    const currentRecipes = this._recentRecipes.value;
    const updatedRecipes = currentRecipes.filter(
      (recipe) => recipe.id !== recipeId
    );
    this._recentRecipes.next(updatedRecipes);
  }

  // Clear the list of recent recipes
  clearRecentRecipes(): void {
    this._recentRecipes.next([]);
  }
}
