import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map, tap } from 'rxjs';
import { Page } from '../models/page.model';
import { Recipe } from '../models/recipe.model';
import { environment } from './../../environments/environment';
import { RecentRecipesService } from './recent-recipes.service';

@Injectable({
  providedIn: 'root',
})
export class RecipeService {
  private BASE_URL = environment.api;

  constructor(
    private http: HttpClient,
    private recentRecipes: RecentRecipesService
  ) {}

  lastViewedRecipes: Recipe[] = [];

  getRecent = () => this.recentRecipes.recentRecipes$;

  getTop = () => this.http.get<Page>(`${this.BASE_URL}/recipes/top`);

  getCookbook = () =>
    this.http.get<Page>(`${this.BASE_URL}/recipes`).pipe(map((i) => i.items));

  getRecipes = () =>
    this.http.get<Page>(`${this.BASE_URL}/recipes`).pipe(map((i) => i.items));

  getRecipe = (recipeId: string): Observable<Recipe> => {
    return this.http.get<Recipe>(`${this.BASE_URL}/recipes/${recipeId}`).pipe(
      tap((recipe) => {
        this.recentRecipes.addRecentRecipe(recipe);
      })
    );
  };

  postRecipe = (recipe: Recipe): Observable<Recipe> =>
    this.http.post<Recipe>(`${this.BASE_URL}/recipes`, recipe);

  putRecipe = (id: string, recipe: Recipe): Observable<Recipe> =>
    this.http.put<Recipe>(`${this.BASE_URL}/recipes/${id}`, recipe);

  deleteRecipe = (recipeId: string) =>
    this.http.delete(`${this.BASE_URL}/recipes/${recipeId}`).pipe(
      tap(() => {
        this.recentRecipes.removeRecentRecipe(recipeId);
      })
    );

  forkRecipe = (recipe: Recipe) => {
    const recipeId = recipe.id!;
    this.recentRecipes.removeRecentRecipe(recipeId);
    recipe.id = null;
    recipe.forked_from_id = recipeId;
    return this.postRecipe(recipe);
  };

  getStatus = () => this.http.get(`${this.BASE_URL}/status`);
}
