import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss'],
})
export class LoadingComponent implements OnInit {
  loadingText: string = '';
  warmingUpMessage = '';

  ngOnInit(): void {
    let dotCount = 0;

    const updateText = () => {
      dotCount = (dotCount + 1) % 4; // Cycle through 0, 1, 2, 3, then back to 0
      this.loadingText = 'Loading' + '.'.repeat(dotCount);
    };

    setTimeout(() => {
      this.loadingText = 'Loading';
    }, 1000);

    // Start a timer that runs every 800ms to update the text
    const intervalId = setInterval(updateText, 333);

    // Optionally, set a timeout to clear the interval after a certain time
    // if the operation finishes or if you want to stop the loading indicator
    const timeoutToClear = 100000; // time after which the interval should be cleared
    setTimeout(() => {
      clearInterval(intervalId); // Stops the interval
    }, timeoutToClear);

    let totalTime = 0;
    const timeOffset = 3500;

    setTimeout(() => {
      this.warmingUpMessage = 'Turning the lights on...';
    }, (totalTime += timeOffset));

    setTimeout(() => {
      this.warmingUpMessage =
        'This is what free tier hosting looks like- no need to worry...';
    }, (totalTime += timeOffset));

    setTimeout(() => {
      this.warmingUpMessage = "We're not worried...";
    }, (totalTime += timeOffset));

    setTimeout(() => {
      this.warmingUpMessage = 'Fingers crossed.';
    }, (totalTime += timeOffset));

    setTimeout(() => {
      this.warmingUpMessage = 'Starting to worry...';
    }, (totalTime += timeOffset));

    setTimeout(() => {
      this.warmingUpMessage = 'How about an apple?';
    }, (totalTime += timeOffset));

    setTimeout(() => {
      this.warmingUpMessage = 'Putting out fires...';
    }, (totalTime += timeOffset));

    setTimeout(() => {
      this.warmingUpMessage =
        "Classic S'more: Graham Cracker + Hershey Chocolate + Toasted Marshmallow...";
    }, (totalTime += timeOffset));

    setTimeout(() => {
      this.warmingUpMessage = 'Roasting marshmallows';
    }, (totalTime += timeOffset));

    setTimeout(() => {
      this.warmingUpMessage =
        'Clearly the free server plan is not cutting it. ';
    }, (totalTime += timeOffset));
  }
}
