import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ValidateLoginComponent } from './auth/validate-login/validate-login.component';
import { CookbookComponent } from './cookbook/cookbook.component';
import { HomeComponent } from './home/home.component';
import { RecipeDetailComponent } from './recipe-detail/recipe-detail.component';
import { RecipeComponent } from './recipe/recipe.component';

const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'cookbook', component: CookbookComponent },
  { path: 'r/edit', component: RecipeDetailComponent },
  { path: 'r/edit/:id', component: RecipeDetailComponent },
  { path: 'r/:id', component: RecipeComponent },
  { path: 'validate-login', component: ValidateLoginComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
