<div class="container">
  <h1 (click)="onHomeClick()" class="header">
    <a
      href=""
      onclick="return false;"
      style="text-decoration: none; color: inherit"
      >R-CPO</a
    >
  </h1>
  <div class="container">
    <button
      *ngIf="auth.isSignedIn$ | async"
      (click)="onCookbookClick()"
      type="button"
    >
      Cookbook
    </button>
    <button
      *ngIf="auth.isSignedIn$ | async"
      type="button"
      (click)="onAddClick()"
    >
      + Add
    </button>
    <button type="button" (click)="onUserClick()">
      {{
        (auth.isSignedIn$ | async) ? (auth.friendlyUsername$ | async) : "Login"
      }}
    </button>
  </div>
</div>
