<div *ngIf="!isComplete">
  <h2 mat-dialog-title>Welcome to R-CPO!</h2>
  <mat-dialog-content>
    <p>
      This is your recipe bot! Create, share, and copy any recipe you like
      without backstory. Just the recipe every time!
    </p>
    <div>
      <mat-form-field>
        <mat-label>Type your email...</mat-label>
        <input type="email" matInput [(ngModel)]="email" />
      </mat-form-field>
      <button mat-button (click)="onSubmit()" cdkFocusInitial>
        Let's cook!
      </button>
    </div>
    <p>If you already have an account we'll log you in.</p>
  </mat-dialog-content>
  <mat-dialog-actions>
    <button mat-button (click)="onCancelClick()">Cancel</button>
  </mat-dialog-actions>
</div>
<div *ngIf="isComplete">
  <p>Check your email!</p>
</div>
