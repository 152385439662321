import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { tap } from 'rxjs';
import { environment } from 'src/environments/environment';
import { LoadingService } from './services/loading.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  public isLoading = false;

  constructor(
    public loadingService: LoadingService,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    if (environment.production) console.log('Production');

    this.loadingService.isLoading$
      .pipe(
        tap((res) => {
          this.isLoading = res;
          this.cd.detectChanges();
        })
      )
      .subscribe();
  }
}
