// src/app/editor/editor.component.ts
import { Component } from '@angular/core';
import { MarkdownService } from 'src/app/services/markdown.service';

@Component({
  selector: 'app-markdown-editor',
  template: `
    <textarea
      [(ngModel)]="content"
      (ngModelChange)="onContentChange()"
    ></textarea>
  `,
})
export class MarkdownEditorComponent {
  content: string = '';

  constructor(private markdownService: MarkdownService) {}

  onContentChange(): void {
    this.markdownService.parse(this.content);
  }
}
