import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Recipe } from '../models/recipe.model';

@Injectable({
  providedIn: 'root',
})
export class ForkService {
  constructor() {}

  private _forkRecipe: BehaviorSubject<Recipe | null> =
    new BehaviorSubject<Recipe | null>(null);
  public readonly forkRecipe$: Observable<Recipe | null> =
    this._forkRecipe.asObservable();

  public setForkRecipe(recipe: Recipe | null) {
    this._forkRecipe.next(recipe);
  }

  public popForkRecipe(): Recipe | null {
    const res = this._forkRecipe.value;
    this._forkRecipe.next(null);
    return res;
  }
}
