import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';

@Injectable()
export class AuthInterceptorService implements HttpInterceptor {
  constructor(private auth: AuthService) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return this.addHeadless(req, next);
  }

  addCookie(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    req = req.clone({ withCredentials: true });
    return next.handle(req);
  }

  addHeadless(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (this.auth.getToken() !== null) {
      req = req.clone({
        setHeaders: { Authorization: 'Bearer ' + this.auth.getToken() },
      });
    }
    return next.handle(req);
    /*
    return this.auth.user$.pipe(
      concatMap((res) => {
        const id = res?.;
        let headers: { [key: string]: string } = {
          Authorization: 'Bearer ' + id,
        };
        req = req.clone({ setHeaders: headers });
        return next.handle(req);
      })
    );
    */
    /*
    return this.auth.user$.pipe(
      concatMap((res) => {
        return from(res?.getIdToken() ?? of(null));
      }),
      concatMap((res) => {
        console.log(res);
        if (!!res) {
          let headers: { [key: string]: string } = {
            Authorization: 'Bearer ' + String(res),
          };
          req.clone({ setHeaders: headers });
          console.log(headers);
        }
        return next.handle(req);
      })
    );
    */
  }
}
