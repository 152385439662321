export const environment = {
  production: false,
  api: 'https://dev.api.r-cpo.com',
  firebase: {
    apiKey: 'AIzaSyBghYOZ5Ea1_vGzu3osjdylScaH-nxPwHo',
    authDomain: 'r-cpo-69495.firebaseapp.com',
    projectId: 'r-cpo-69495',
    storageBucket: 'r-cpo-69495.appspot.com',
    messagingSenderId: '663553268608',
    appId: '1:663553268608:web:f139a726c271071df67f65',
    measurementId: 'G-Q36RTCVC9Y',
  },
};
