import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, tap } from 'rxjs';
import { Recipe } from '../models/recipe.model';
import { ForkService } from '../services/fork.service';
import { LoadingService } from '../services/loading.service';
import { MarkdownService } from '../services/markdown.service';
import { RecipeService } from '../services/recipe.service';

@Component({
  selector: 'app-recipe-detail',
  templateUrl: './recipe-detail.component.html',
  styleUrls: ['./recipe-detail.component.scss'],
})
export class RecipeDetailComponent {
  constructor(
    private fb: FormBuilder,
    private recipeService: RecipeService,
    private route: ActivatedRoute,
    private router: Router,
    private markdownService: MarkdownService,
    public loadingService: LoadingService,
    private forkService: ForkService
  ) {}

  id?: string | null;
  form!: FormGroup;
  body = '';
  titleFormatted = '';
  bodyFormatted = '';
  showPreview = false;

  ngOnInit() {
    this.id = this.route.snapshot.paramMap.get('id');
    this.initForm();
    if (this.id) {
      this.load(this.id).subscribe();
    } else {
      let forkRecipe = this.forkService.popForkRecipe();
      if (forkRecipe != null) {
        forkRecipe.forked_from_id = forkRecipe.id;
        forkRecipe.id = null;
        this.setForm(forkRecipe);
      }
    }
  }

  private initForm() {
    this.form = this.fb.group({
      id: [null],
      title: ['', Validators.required],
      body: ['', Validators.required],
      body_type: [1],
      status: [1],
      forked_from_id: [null],
    });
  }

  private load(id: string): Observable<Recipe> {
    return this.recipeService
      .getRecipe(id)
      .pipe(tap((res) => this.setForm(res)));
  }

  private setForm(recipe: Recipe): void {
    this.form.reset(recipe);
    this.body = recipe.body;
    this.bodyFormatted = this.markdownService.parse(recipe.body);
  }

  onSubmit() {
    if (this.form.valid) {
      const save$ = !!this.id
        ? this.recipeService.putRecipe(this.id, this.form.getRawValue())
        : this.recipeService.postRecipe(this.form.getRawValue());

      save$
        .pipe(
          tap((res) => this.form.reset(res)),
          tap((res) => {
            this.router.navigate(['r', res!.id]);
          })
        )
        .subscribe();
    } else {
      console.error('Form is invalid');
    }
  }

  onCancel() {
    this.router.navigate(['./']);
  }

  onDelete() {
    if (!this.id) return;
    if (!confirm('Are you sure to delete this recipe?')) return;
    this.recipeService
      .deleteRecipe(this.id)
      .pipe(
        tap(() => {
          this.router.navigate(['cookbook']);
        })
      )
      .subscribe();
  }

  onContentChange() {
    this.form.get('body')?.patchValue(this.body);
    this.bodyFormatted = this.markdownService.parse(this.body);

    //this.titleFormatted = this.bodyFormatted.inn
    if (this.form.get('title')?.untouched)
      this.form
        .get('title')
        ?.patchValue(extractContentFromFirstTag(this.bodyFormatted));
  }
}

function extractContentFromFirstTag(text: string): string | null {
  // Create a new DOM parser instance
  const parser = new DOMParser();

  // Parse the string into a Document object
  const doc = parser.parseFromString(text, 'text/html');

  // Get the first element in the body of the parsed document
  const firstElement = doc.body.firstElementChild;

  // Return the inner text if an element is found, otherwise return null
  return firstElement ? firstElement.textContent : null;
}
