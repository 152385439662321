<div *ngIf="recipe && !(loadingService.isLoading$ | async)">
  <div class="recipe-container">
    <app-markdown-preview [content]="recipe.body"></app-markdown-preview>
  </div>
  <div class="recipe-details-options">
    <div>
      <button
        *ngIf="recipe.forked_from_id !== null"
        class="button"
        (click)="onForkParentClick()"
      >
        Forked from
      </button>
    </div>
    <div *ngIf="auth.isSignedIn$ | async">
      <button *ngIf="recipe.user_owned" class="button" (click)="onEditClick()">
        Edit
      </button>
      <button *ngIf="!recipe.user_owned" class="button" (click)="onForkClick()">
        Fork
      </button>
    </div>
  </div>
</div>
