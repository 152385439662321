import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { Recipe } from '../models/recipe.model';

@Component({
  selector: 'app-recipe-list-item',
  templateUrl: './recipe-list-item.component.html',
  styleUrls: ['./recipe-list-item.component.scss'],
})
export class RecipeListItemComponent {
  constructor(private router: Router) {}
  @Input() recipe!: Recipe;

  onClick(recipe: Recipe): void {
    this.router.navigate(['r', recipe.id]);
  }
}
