import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { AuthService } from '../../auth.service';
import { AuthDialogComponent } from '../auth-dialog.component';

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss'],
})
export class SignInComponent {
  email: string = '';
  isComplete = false;
  emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  constructor(
    private auth: AuthService,
    public dialogRef: MatDialogRef<AuthDialogComponent>
  ) {}

  onSubmit() {
    if (!this.email || !this.emailRegex.test(this.email)) {
      return;
    }
    this.auth.sendSignInLinkToEmail(this.email);
    this.isComplete = true;
  }

  onCancelClick(): void {
    this.dialogRef.close();
  }
}
