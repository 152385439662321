// src/app/preview/preview.component.ts
import { Component, Input, OnInit } from '@angular/core';
import { MarkdownService } from 'src/app/services/markdown.service';

@Component({
  selector: 'app-markdown-preview',
  template: '<div [innerHTML]="parsedContent"></div>',
})
export class MarkdownPreviewComponent implements OnInit {
  @Input() content: string | undefined = '';
  parsedContent: string = '';

  constructor(private markdownService: MarkdownService) {}

  ngOnInit(): void {
    this.parsedContent = this.markdownService.parse(this.content ?? '');
  }
}
