import { Component, OnInit } from '@angular/core';
import { concatMap, filter, tap } from 'rxjs';
import { Recipe } from '../models/recipe.model';
import { LoadingService } from '../services/loading.service';
import { RecipeService } from '../services/recipe.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  recipes: Recipe[] = [];

  constructor(
    private recipeService: RecipeService,
    public loadingService: LoadingService
  ) {}

  ngOnInit(): void {
    this.recipeService
      .getRecent()
      .pipe(
        tap((res) => (this.recipes = res)),
        filter((res) => res.length < 10),
        concatMap(() => this.recipeService.getTop()),
        tap((res) => {
          const topRecipes = res?.items?.filter(
            (i: Recipe) =>
              this.recipes.findIndex((s: Recipe) => s.id === i.id) < 0
          );

          this.recipes = this.recipes.concat(
            topRecipes?.slice(0, 10 - this.recipes.length)
          );
        })
      )
      .subscribe();
  }
}
