import { Component, OnInit } from '@angular/core';
import { tap } from 'rxjs';
import { Recipe } from '../models/recipe.model';
import { LoadingService } from '../services/loading.service';
import { RecipeService } from '../services/recipe.service';

@Component({
  selector: 'app-cookbook',
  templateUrl: './cookbook.component.html',
  styleUrls: ['./cookbook.component.scss'],
})
export class CookbookComponent implements OnInit {
  recipes: Recipe[] = [];

  constructor(
    private recipeService: RecipeService,
    public loadingService: LoadingService
  ) {}

  ngOnInit(): void {
    this.recipeService
      .getCookbook()
      .pipe(
        tap((res) => {
          this.recipes = res;
        })
      )
      .subscribe();
  }
}
