<div
  *ngIf="!(loadingService.isLoading$ | async)"
  [ngClass]="recipes.length === 0 ? 'container' : null"
>
  <app-recipe-list
    *ngIf="recipes.length > 0"
    [recipes]="recipes"
  ></app-recipe-list>
  <div *ngIf="recipes.length === 0">
    <p>No cookbook recipes. Fork an existing recipe or add a new one!</p>
  </div>
</div>
