import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { tap } from 'rxjs';
import { AuthService } from '../auth/auth.service';
import { Recipe } from '../models/recipe.model';
import { ForkService } from '../services/fork.service';
import { LoadingService } from '../services/loading.service';
import { RecipeService } from '../services/recipe.service';

@Component({
  selector: 'app-recipe',
  templateUrl: './recipe.component.html',
  styleUrls: ['./recipe.component.scss'],
})
export class RecipeComponent implements OnInit {
  recipe!: Recipe;

  constructor(
    private recipeService: RecipeService,
    private route: ActivatedRoute,
    private router: Router,
    public auth: AuthService,
    public loadingService: LoadingService,
    private forkService: ForkService
  ) {}

  ngOnInit(): void {
    this.route.params
      .pipe(
        tap((p) => {
          this.recipeService
            .getRecipe(p['id'])
            .pipe(
              tap((recipe) => {
                this.recipe = recipe;
              })
            )
            .subscribe();
        })
      )
      .subscribe();
  }

  onEditClick(): void {
    this.router.navigate(['r', 'edit', this.recipe.id]);
  }

  onForkClick(): void {
    this.forkService.setForkRecipe(this.recipe);
    this.router.navigate(['r', 'edit']);
  }

  onForkParentClick(): void {
    if (this.recipe.forked_from_id === null) return;
    this.router.navigate(['r', this.recipe.forked_from_id!]);
  }
}
