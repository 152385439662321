<div *ngIf="!(loadingService.isLoading$ | async)">
  <form [formGroup]="form" (ngSubmit)="onSubmit()">
    <div>
      <ng-container *ngIf="!showPreview; else preview">
        <div class="form-group">
          <label for="recipe">Recipe</label>
          <textarea
            id="recipe"
            [ngModelOptions]="{ standalone: true }"
            [(ngModel)]="body"
            (ngModelChange)="onContentChange()"
          ></textarea>
        </div>

        <!-- Title -->
        <div class="form-group">
          <label for="title">Title</label>
          <input type="text" id="title" formControlName="title" required />
        </div>
      </ng-container>

      <ng-template #preview>
        <!--Preview area-->
        <div class="preview">
          <div id="preview" [innerHTML]="bodyFormatted"></div>
        </div>
      </ng-template>

      <!-- Save and Cancel Buttons -->
      <div class="form-group">
        <button type="submit" (click)="(onSubmit)" [disabled]="!form.valid">
          Save
        </button>
        <button type="button" (click)="onCancel()">Cancel</button>
        <button *ngIf="id" type="button" (click)="onDelete()">Delete</button>
        <mat-slide-toggle
          class="preview-toggle"
          [ngModelOptions]="{ standalone: true }"
          [(ngModel)]="showPreview"
          [disabled]="body.length === 0"
          >{{ showPreview ? "Edit" : "Preview" }}</mat-slide-toggle
        >
      </div>
    </div>
  </form>
</div>
