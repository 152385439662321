import { Injectable } from '@angular/core';
import { BehaviorSubject, map } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LoadingService {
  private isLoading$$ = new BehaviorSubject<boolean>(false);
  isLoading$ = this.isLoading$$.asObservable();
  isNotLoading$ = this.isLoading$$.asObservable().pipe(map((res) => !res));

  setLoading(isLoading: boolean) {
    this.isLoading$$.next(isLoading);
  }
}
